
.min-box-header{
  display:flex;
  align-items: center;
  justify-content:space-between;
  margin:0 20px;
  padding-bottom: 14px;
  border-bottom: 2px solid #F1F5FF;
  ::v-deep .el-breadcrumb{
    .el-breadcrumb__item{
      .el-breadcrumb__inner{
        color: #999999;
      }
      &:last-of-type{
        .el-breadcrumb__inner{
          color: #333333;
        }
      }
    }
  }
}
.main-box {
  height: 100%;
}

.main-wrapper {
  height: 100%;

  ::v-deep > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
